import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { uri: String, locale: String }

  connect () {
    this.connectUV()
  }

  connectUV () {
    window.addEventListener('uvLoaded', () => {
      var urlDataProvider = new UV.URLDataProvider(true)

      const urlParams = new URLSearchParams(window.location.search)
      const manifestUri = urlParams.get('manifest') || this.uriValue
      const cv = urlParams.get('cv') || 0

      createUV (this.element, {
        root: '../uv',
        iiifResourceUri: manifestUri,
        configUri: urlDataProvider.get('config'),
        collectionIndex: Number(urlDataProvider.get('c', 0)),
        manifestIndex: Number(urlDataProvider.get('m', 0)),
        sequenceIndex: Number(urlDataProvider.get('s', 0)),
        canvasIndex: Number(urlDataProvider.get('cv', cv)),
        rangeId: urlDataProvider.get('rid', 0),
        rotation: Number(urlDataProvider.get('r', 0)),
        xywh: urlDataProvider.get('xywh', ''),
        embedded: true,
        locales: this.locales()
      }, urlDataProvider)
    }, { capture: false, once: true })

    this.connectResizeObserver()
  }

  connectResizeObserver () {
    let resizeObserver = new ResizeObserver(() => {
      dispatchEvent(new Event('resize'))
    })
    resizeObserver.observe(this.element)
  }

  locales() {
    switch (this.localeValue) {
      case 'cy':
        return [{ name: 'cy-GB' }]
      case 'en':
        return [{ name: 'en-GB' }]
      case 'fr':
        return [{ name: 'fr-FR' }]
      case 'pl':
        return [{ name: 'pl-PL' }]
      case 'sv':
        return [{ name: 'sv-SE' }]
      default:
        return this.localeValue
    }
  }
}
