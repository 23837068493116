import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.addTitleToTargetBlankLinks()
    this.addDescribedByToInputs()
  }

  addTitleToTargetBlankLinks() {
    document.querySelectorAll("a[target='_blank']").forEach(a => {
      if (typeof a.title !== 'undefined' && a.title !== null && a.title !== '') {
        a.title = `Otwarcie w nowej karcie: ${a.title}`
      } else {
        a.title = `Otwarcie w nowej karcie`
      }
    });
  }

  // set aria-describedby dor inputs in bootstrap forms
  addDescribedByToInputs() {
    const form_groups = document.querySelectorAll('form .form-group')
    form_groups.forEach(group => {
      const invalid = group.querySelector('.invalid-feedback')
      const hint = group.querySelector('.form-text')
      const input = group.querySelector('input:not([type="hidden"])')
      const described_by = [];

      if (invalid) {
        const invalid_id = `${input.id}_invalid`;
        invalid.id = invalid_id
        described_by.push(invalid_id);
      }
      if (hint) {
        const hint_id = `${input.id}_hint`;
        hint.id = hint_id
        described_by.push(hint_id);
      }
      if (described_by.length > 0) {
        input.setAttribute('aria-describedby', described_by.join(' '));
      }
    });
  }

}