import { Controller } from "@hotwired/stimulus";
const vis = require('vis-timeline');
import 'vis-timeline/dist/vis-timeline-graph2d.css';
const noUiSlider = require('nouislider');
import 'nouislider/distribute/nouislider.css';

export default class extends Controller {
  static targets = [
    "content",
    "rangeSlider",
    "rangeMin",
    "rangeMax",
    "zoomIn",
    "zoomOut",
  ]

  connect() {
    const url = new URL(window.location)
    this.min = parseInt(this.data.get('min'))
    this.max = parseInt(this.data.get('max'))
    this.start = parseInt(url.searchParams.get('start') || this.min)
    this.end = parseInt(url.searchParams.get('end') || this.max)
    this.setupInputs();
    this.setupSlider();
    this.addAriaAttrs()
    this.setupTimeline([]);
    this.setupCallbacks();
  }

  setupInputs() {
    this.rangeMinTarget.value = this.start
    this.rangeMaxTarget.value = this.end
    this.rangeMinTarget.min = this.rangeMaxTarget.min = this.min
    this.rangeMinTarget.max = this.rangeMaxTarget.max = this.max
  }

  setupSlider() {
    this.slider = noUiSlider.create(this.rangeSliderTarget, {
      start: [this.start, this.end],
      connect: true,
      range: {
        'min': this.min,
        'max':  this.max,
      },
      step: 1,
      margin: 1,
    })
    // aria
    this.slider.target.querySelector('[role="slider"].noUi-handle-lower')
  }

  setupTimeline(data) {
    // Create a DataSet (allows two way data-binding)
    let items = new vis.DataSet(data);

    // Configuration for the Timeline
    let options = {
      minHeight: '300px',
      orientation: {
        axis: 'both',
        item: 'top',
      },
      template: (item, element, data) => {
        return `
        <div class="card">
          <img src="${item.thumbnail_url}" class="card-img" alt="Okładka książki" />
          <a class="card-img-overlay text-white" href="/search_results/${item.id}">
            ${item.raw_date}<br/>
            ${item.title}
          </a>
        </div>
        `
      },
      min: new Date(this.min, 0),
      start: new Date(this.start, 0),
      max: new Date(this.max, 11, 31),
      end: new Date(this.end, 11, 31),
      zoomMin: 3 * 31536000000,
      format: {
        minorLabels: (date, scale) => scale === 'year' ? date.year() : ''
      },
      showCurrentTime: false,
      zoomKey: 'altKey',
    };

    // Create a Timeline
    this.timeline = new vis.Timeline(this.contentTarget, items, options);
  }

  setupCallbacks() {
    // inputs
    this.rangeMinTarget.addEventListener('change', () => {
      this.start = this.rangeMinTarget.value
      this.updateRangeSlider()
      this.updateTimelineRange()
    })
    this.rangeMaxTarget.addEventListener('change', () => {
      this.end = this.rangeMaxTarget.value
      this.updateRangeSlider()
      this.updateTimelineRange()
    })

    // slider
    this.slider.on('slide', ([start, end]) => {
      this.start = parseInt(start)
      this.end = parseInt(end)
      this.updateRangeInputs()
      this.updateTimelineRange()
    })
    this.slider.on('change', () => {
      this.loadData()
    })

    // zoom
    this.zoomInTarget.addEventListener('click', () => {
      this.timeline.zoomIn(0.2)
    })
    this.zoomOutTarget.addEventListener('click', () => {
      this.timeline.zoomOut(0.2)
    })


    // timeline
    this.timeline.on('rangechanged', props => {
      // console.log('Timeline rangechanged')
      // console.log('by user', props.byUser);
      // console.log('new range', props.start, props.end)
      const start = props.start.getFullYear()
      const end = props.end.getFullYear()
      if (start !== this.start || end !== this.end || !this.itemSet) {
        this.start = start
        this.end = end
        this.updateRangeInputs()
        this.updateRangeSlider()
        this.updateURLSearchParams()
        this.loadData()
      }
    })
  }

  updateRangeInputs() {
    this.rangeMinTarget.value = this.start
    this.rangeMaxTarget.value = this.end
    // console.log('updated RangeInputs');
  }

  updateRangeSlider() {
    this.slider.setHandle(0, this.start)
    this.slider.setHandle(1, this.end)
    // console.log('updated RangeSlider');
  }

  updateZoomBtns() {
    // TODO
  }

  updateTimelineRange() {
    this.timeline.setWindow(new Date(this.start, 0), new Date(this.end, 11, 31))
    // console.log('updated TimelineRange')
  }

  updateURLSearchParams() {
    const params = new URLSearchParams(location.search);
    params.set('start', this.start);
    params.set('end', this.end);
    history.replaceState({}, '', `${location.pathname}?${params}`);
  }

  loadData() {
    const url = new URL(this.data.get('url'))
    url.searchParams.set('start', this.start)
    url.searchParams.set('end', this.end)
    // console.log('Loading', url);
    fetch(url)
      .then(response => response.json())
      .then(response => {
        // console.log('loaded', response)
        let items = new vis.DataSet(response.map((
          {id, title, thumbnail_url, start, end, raw_date}) => {
            start = (new Date(start).getTime() + new Date(end).getTime()) / 2
            return { id, title, thumbnail_url, start, raw_date}
          }
        ));
        this.timeline.setData({
          items: items
        });
        // this.timeline.fit();
        // this.timeline.redraw();
      })
    // console.log('loaded data');
  }

  addAriaAttrs() {
    this.rangeMinTarget.setAttribute('aria-label', "Zakres: od roku")
    const lowerSliderHandle = this.slider.target.querySelector('.noUi-handle-lower')
    if (lowerSliderHandle) lowerSliderHandle.setAttribute('aria-labelledby', this.rangeMinTarget.id)
    this.rangeMaxTarget.setAttribute('aria-label', "Zakres: do roku")
    const upperSliderHandle = this.slider.target.querySelector('.noUi-handle-upper')
    if (upperSliderHandle) upperSliderHandle.setAttribute('aria-labelledby', this.rangeMaxTarget.id)
  }
}
