import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["advancedSearchButton", "simpleSearchInput", "advancedSearchBox"]

  connect() {
    this.simpleSearchInputPlaceholder = this.simpleSearchInputTarget.placeholder;
    const box = this.advancedSearchBoxTarget
    this.advancedSearchFirstInput = box.querySelector('input[name="q[indexes_attributes][1][value]')
    this.advancedSearchFirstId = box.querySelector('select[name="q[indexes_attributes][1][id]')

    if(this.advancedSearchBoxTarget.classList.contains('active')){
      this.enableAdvancedSearch()
    } else {
      this.disableAdvancedSearch()
    }
  }

  enableAdvancedSearch() {
    this.simpleSearchInputTarget.value = "";
    this.simpleSearchInputTarget.placeholder = "Wyszukiwanie zaawansowane";
    this.simpleSearchInputTarget.disabled = true;
    const box = this.advancedSearchBoxTarget
    box.querySelectorAll('input').forEach(input => { input.disabled = false; });
    box.querySelectorAll('select').forEach(select => { select.disabled = false; });
    this.advancedSearchBoxTarget.classList.add('active')
  }

  disableAdvancedSearch() {
    this.simpleSearchInputTarget.disabled = false;
    const box = this.advancedSearchBoxTarget
    box.querySelectorAll('input').forEach(input => { input.disabled = true; });
    box.querySelectorAll('select').forEach(select => { select.disabled = true; });
    this.advancedSearchBoxTarget.classList.remove('active')
  }

  open() {
    if(this.advancedSearchBoxTarget.classList.contains('show')){
      this.hide()
    } else {
      this.show()
      if(!this.advancedSearchBoxTarget.classList.contains('active')){
        if(this.advancedSearchFirstInput.value == '') {
          this.advancedSearchFirstInput.value = this.simpleSearchInputTarget.value
        }
        this.enableAdvancedSearch()
      }
      this.advancedSearchFirstId.focus()
    }
  }

  close() {
    this.hide()
    this.simpleSearchInputTarget.placeholder = this.simpleSearchInputPlaceholder;
    this.disableAdvancedSearch()
    this.simpleSearchInputTarget.value = this.advancedSearchFirstInput.value
    this.simpleSearchInputTarget.focus()
  }

  show() {
    this.advancedSearchButtonTarget.setAttribute('aria-expanded', 'true')
    this.advancedSearchBoxTarget.classList.add('show')
  }

  hide(){
    this.advancedSearchButtonTarget.setAttribute('aria-expanded', 'false')
    this.advancedSearchBoxTarget.classList.remove('show')
  }
}