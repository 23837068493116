// TODO: update modal after upgrade to bootstrap 5

import { Controller } from "@hotwired/stimulus"
import Modal from "lib/bootstrap/modal"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.modal = new Modal(this.modalTarget, { focus: true })
    this.modal.show()
  }

  disconnect() {
    this.modal.hide()
  }
}
