// TODO: update modal after upgrade to bootstrap 5

import { Controller } from "@hotwired/stimulus"
import Modal from "lib/bootstrap/modal"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.modal = new Modal(this.modalTarget, { focus: true })
  }

  close() {
    this.modal.hide()
  }

  open(event) {
    event.preventDefault()
    this.modal.show()
    this.modalTarget.after(document.querySelector(".modal-backdrop"));
  }
}
